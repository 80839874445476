<template>
  <div class="pado-app">
    <div class="pado_top" style="background-color:#EEEEEE">
      <div class="top fx fxAlign">
        <img src="@/assets/icon/icon_host18.png" />
        <i class="el-icon-arrow-right elIcon"></i>
        <span @click="topRouter">{{ tableinfo.dbName }}数据库</span>
        <i class="el-icon-arrow-right elIcon"></i>

        <span>{{ tableinfo.datable }}数据</span>
      </div>
    </div>
    <div class="pado_main">
      <div class="main">
        <div class="main_title fx">
          <div class="title">字段含义</div>
          <div class="title">字段</div>
          <div class="title_1">内容</div>
        </div>
        <div
          class="main_title fx"
          v-for="(item, index) in fieldinfo"
          :key="index"
        >
          <div class="title">{{ item.dbFieldMeaning }}</div>
          <div class="title">{{ item.dbField }}</div>
          <div class="title_1">{{ item.content }}</div>
        </div>
        <!-- <div class="main_title">
          <div class="title">
            <span>内容:</span>
            {{ info }}
          </div>
        </div> -->
        <!-- <div class="main_title">
          <div class="title">
            <span>简介:</span>
            数据处理数据处理与数值计算相比较，它的主值计算值计算要特点是原始数据多，处理量大，时间性强，要特点是原但计算公式并不复
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { INSERT, QUERY, DELETE, UPDATE, QUERYED } from "@/services/dao.js";
import axios from "axios";
export default {
  data() {
    return {
      id: "", //数据库id
      dataId: "", //数据id
      tableinfo: "",
      fieldinfo: [],
      info: [],
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.dataId = this.$route.query.dataId;
    console.log(this.$route.query);
    this.init();
  },
  methods: {
    async init() {
      this.fieldinfo = [];
      let info = await QUERYED(
        "post",
        "",
        'dbInformation( where: {id: {_eq: "' +
          this.id +
          '"}},offset: ' +
          0 +
          ") {id dbName dbProvider dbUrl chargeType resourceNum uploadTime datable }"
      );
      let info_table = await QUERYED(
        "post",
        "",
        'dbFieldInformation( where: {dbnam : {_like: "%' +
          info.data.dbInformation[0].dbUrl +
          '%"}},offset: ' +
          0 +
          ") {id dbField  dbFieldMeaning    }"
      );

      this.tableinfo = info.data.dbInformation[0];
      const token = localStorage.getItem("Authorization");
      const formData = new FormData();
      let that = this;
      formData.append("dbPath", info.data.dbInformation[0].dbUrl);
      formData.append("table", info.data.dbInformation[0].datable);
      formData.append("id", this.dataId);
      await axios
        .post(
          this.api.LoginURL.concat("/common/resolverOne"),
          formData,

          {
            headers: {
              token: ` ${token}`,
            },
          }
        )
        .then((response) => {
          that.info = JSON.parse(JSON.stringify(response.data.data));
        })
        .catch(function(error) {
          console.log("错误" + error);
        });

      for (let i = 0; i < info_table.data.dbFieldInformation.length; i++) {
        let obj = JSON.parse(JSON.stringify(this.info[0]));

        var eValue = eval(
          "obj." + info_table.data.dbFieldInformation[i].dbField
        );

        this.fieldinfo.push({
          id: info_table.data.dbFieldInformation[i].id,
          dbField: info_table.data.dbFieldInformation[i].dbField,
          dbFieldMeaning: info_table.data.dbFieldInformation[i].dbFieldMeaning,
          content: eValue,
        });
      }
      // console.log(this.fieldinfo);
    },
    //返回上两页
    topRouter() {
      this.$router.push({
        path: "/Knowledge_introduction",
        query: { id: this.id },
      });
    },
    //返回上一页
    topRouter1() {
      this.$router.push({ path: "/payDoload", query: { id: this.id } });
    },
  },
};
</script>

<style src="../../../styles/public.css" scoped></style>
<style scoped>
.pado_top {
  height: 40px;
}
.top {
  margin: 0 100px 0 130px;
  height: 100%;
}
.elIcon {
  margin: 0 5px;
  font-size: 16px;
  color: #666;
}
.pado_main {
  width: 100%;
  padding-top: 10px;
  background-color: #f7f7f7;
}
.main {
  margin: 0 100px 0 130px;
}
.main_title {
  width: 100%;
  /* height: 42px; */
  padding: 10px 0;
  /* background-color: #E5E5E5; */
}
.main_title:nth-child(2n-1) {
  background-color: #e5e5e5;
}
.title {
  padding: 0 20px;
  font-size: 14px;
  color: #333;
  font-weight: bold;
  width: 10%;
  text-align: center;
}
.title_1 {
  padding: 0 20px;
  font-size: 14px;
  color: #333;
  font-weight: bold;
  width: 80%;
}
</style>
